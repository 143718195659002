import React from 'react';

export default function HowtouseText() {
    return (
        <div >
          <div className = "langCng" lang="en">HOW TO USE</div>
          <div className = "langCng" lang="ru">КАК ПОЛЬЗОВАТЬСЯ</div>
          <div className = "langCng" lang="fr">COMMENT UTILISER</div>
          <div className = "langCng" lang="de">WIE BENUTZT MAN</div>
          <div className = "langCng" lang="it">COME USARE</div>
          <div className = "langCng" lang="fi">KUINKA KÄYTTÄÄ</div>
          <div className = "langCng" lang="es">CÓMO UTILIZAR</div>
          <div className = "langCng" lang="tr">NASIL KULLANILIR</div>
          <div className = "langCng" lang="ms">BAGAIMANA NAK GUNA</div>
          <div className = "langCng" lang="ta">எப்படி உபயோகிப்பது</div>
          <div className = "langCng" lang="id">CARA PENGGUNAAN</div>
          <div className = "langCng" lang="pt">COMO USAR</div>
          <div className = "langCng" lang="hi">कैसे इस्तेमाल करे</div>
          <div className = "langCng" lang="ar">كيف تستعمل</div>
          <div className = "langCng" lang="ja">使い方</div>
          <div className = "langCng" lang="zh">如何使用</div>
          <div className = "langCng" lang="ko">사용법</div>
        </div>
    );
  }