import React from 'react';

export default function DownloadText() {
    return (
        <div >
          <div className = "langCng" lang="en">DOWNLOAD</div>
          <div className = "langCng" lang="ru">СКАЧАТЬ</div>
          <div className = "langCng" lang="fr">TÉLÉCHARGER</div>
          <div className = "langCng" lang="de">HERUNTERLADEN</div>
          <div className = "langCng" lang="it">SCARICA</div>
          <div className = "langCng" lang="fi">LADATA</div>
          <div className = "langCng" lang="es">DESCARGAR</div>
          <div className = "langCng" lang="tr">İNDİRMEK</div>
          <div className = "langCng" lang="ms">MUAT TURUN</div>
          <div className = "langCng" lang="ta">பதிவிறக்க</div>
          <div className = "langCng" lang="id">UNDUH</div>
          <div className = "langCng" lang="pt">DOWNLOAD</div>
          <div className = "langCng" lang="hi">डाउनलोड</div>
          <div className = "langCng" lang="ar">تحميل</div>
          <div className = "langCng" lang="ja">ダウンロード</div>
          <div className = "langCng" lang="zh">下载</div>
          <div className = "langCng" lang="ko">다운로드</div>
        </div>
    );
  }