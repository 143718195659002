import React from 'react';
import config from '../../config';

export default function Social() {
  return (
    <div>
      <ul className="list-inline list-social langCng" lang='en'>
        {config.socialLinks_en.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='ja'>
        {config.socialLinks_ja.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='ru'>
        {config.socialLinks_ru.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='fr'>
        {config.socialLinks_fr.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='de'>
        {config.socialLinks_de.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='it'>
        {config.socialLinks_it.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='zh'>
        {config.socialLinks_zh.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>


      <ul className="list-inline list-social langCng" lang='ko'>
        {config.socialLinks_ko.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='id'>
        {config.socialLinks_id.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='pt'>
        {config.socialLinks_pt.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='ar'>
        {config.socialLinks_ar.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='hi'>
        {config.socialLinks_hi.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='es'>
        {config.socialLinks_es.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='fi'>
        {config.socialLinks_en.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>
      

      <ul className="list-inline list-social langCng" lang='tr'>
        {config.socialLinks_en.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='ms'>
        {config.socialLinks_en.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>

      <ul className="list-inline list-social langCng" lang='ta'>
        {config.socialLinks_en.map(social => {
          const { icon, url } = social;
          return (
            <li key={url} className="list-inline-item  ">
              <a href={url} target="_blank" rel="noreferrer noopener">
                <i className={`fab  ${icon}`}></i>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
