export const youtubeLink = {
    en : "yyy3ElcO8bc",
    ja : "t8XLm5WQiGg",
    es : "LRPFdIyxfZc",
    ru : "bxHt8OP_0Qc",
    fr : "HWF4klmC07I",
    de : "MYI4b3MI-2c",
    it : "sc2W1x2zEow",
    zh : "vnidoUv1c4g",
    ko : "k2Fp8x49FiM",
    ar : "-5rg3wutX7k",
    hi : "94ENT7aH6hM",
    id : "LbGngpjJ8Oo",
    pt : "1pc8YQXckOo",
  }